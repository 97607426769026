const CommentQuestionIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='23.999'
      viewBox='0 0 24 23.999'
    >
      <path
        id='comment-question'
        d='M11.9,16a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,11.9,16Zm1-2a1.9,1.9,0,0,1,.928-1.512A4,4,0,1,0,7.9,8.981a1,1,0,0,0,2,0,2,2,0,0,1,2.371-1.967,2.024,2.024,0,0,1,1.6,1.6,2,2,0,0,1-1,2.125A3.9,3.9,0,0,0,10.9,14a1,1,0,0,0,2,0ZM24,19V12.34A12.21,12.21,0,0,0,12.836.028,12,12,0,0,0,.03,12.854C.47,19.208,6.082,24,13.084,24H19a5.006,5.006,0,0,0,5-5ZM12.7,2.024A10.2,10.2,0,0,1,22,12.339V19a3,3,0,0,1-3,3H13.083C7.048,22,2.4,18.1,2.024,12.715A10,10,0,0,1,12.015,2c.228,0,.457.008.685.023Z'
        transform='translate(-0.001 -0.001)'
        fill='#4c677b'
      />
    </svg>
  );
};

export default CommentQuestionIcon;
