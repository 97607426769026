const SearchIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      //xmlns:xlink='http://www.w3.org/1999/xlink'
      width='23.285'
      height='23.285'
      viewBox='0 0 23.285 23.285'
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='0.303'
          y1='-0.174'
          x2='0.833'
          y2='1.167'
          gradientUnits='objectBoundingBox'
        >
          <stop
            offset='0'
            stopColor='#13714d'
          />
          <stop
            offset='1'
            stopColor='#88cb60'
          />
        </linearGradient>
      </defs>
      <path
        id='search_2_'
        data-name='search (2)'
        d='M22.969,21.6l-5.783-5.783a9.7,9.7,0,1,0-1.37,1.37L21.6,22.969a.969.969,0,1,0,1.37-1.37ZM9.688,17.439a7.751,7.751,0,1,1,7.751-7.751,7.751,7.751,0,0,1-7.751,7.751Z'
        transform='translate(0.032 0.032)'
        fill='url(#linear-gradient)'
      />
    </svg>
  );
};

export default SearchIcon;
