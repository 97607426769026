const SaudiArabiaIcon = () => {
  return (
    <svg
      id='saudi-arabia_2_'
      data-name='saudi-arabia (2)'
      xmlns='http://www.w3.org/2000/svg'
      width='23.286'
      height='16'
      viewBox='0 0 23.286 16'
    >
      <path
        id='Path_142302'
        data-name='Path 142302'
        d='M23.286,91.309A2.268,2.268,0,0,1,21.033,93.6H2.254A2.268,2.268,0,0,1,0,91.309V79.891A2.268,2.268,0,0,1,2.254,77.6H21.033a2.268,2.268,0,0,1,2.254,2.291Z'
        transform='translate(0 -77.6)'
        fill='#096'
      />
      <path
        id='Path_142303'
        data-name='Path 142303'
        d='M48,77.6H66.779a2.268,2.268,0,0,1,2.254,2.291V91.309A2.268,2.268,0,0,1,66.779,93.6'
        transform='translate(-45.746 -77.6)'
        fill='#038e5c'
      />
      <g
        id='Group_26392'
        data-name='Group 26392'
      >
        <path
          id='Path_142304'
          data-name='Path 142304'
          d='M23.286,369.6a2.268,2.268,0,0,1-2.254,2.291H2.254A2.268,2.268,0,0,1,0,369.6'
          transform='translate(0 -355.891)'
          fill='#007a54'
        />
        <path
          id='Path_142305'
          data-name='Path 142305'
          d='M48,77.6H66.779a2.268,2.268,0,0,1,2.254,2.291V84.06'
          transform='translate(-45.746 -77.6)'
          fill='#007a54'
        />
      </g>
      <g
        id='Group_26393'
        data-name='Group 26393'
        transform='translate(2.198 3.388)'
      >
        <path
          id='Path_142306'
          data-name='Path 142306'
          d='M57.75,150.523c.15-.113.15.188,0,.526a.923.923,0,0,0,.188-.563c-.038-.263-.188-.225-.3-.075C57.562,150.523,57.6,150.6,57.75,150.523Z'
          transform='translate(-57.093 -150.269)'
          fill='#fff'
        />
        <path
          id='Path_142307'
          data-name='Path 142307'
          d='M47.5,152.166c.188-.338.113-.6.263-1.089.113-.3.113-.075.113.113-.075.488-.038,1.277.639,1.239.038.451.15,1.239.15,1.54,0,.263.038.188.113.038a1.333,1.333,0,0,0,.038-.563c0-.225-.038-.638-.075-1.2a1.4,1.4,0,0,0,.225-.263c.075-.188.113-.15.15,0,.075.3.376.488.751.188.376-.263.225-.789.038-1.352.15.038.188-.075.113-.225-.038-.075-.113-.15-.15-.225-.075-.075-.113-.15-.188-.075s-.113.15-.15.188-.038.113,0,.225a4.371,4.371,0,0,1,.263.9c.038.188-.038.225-.188.225-.113,0-.3-.038-.376-.338a1.342,1.342,0,0,1-.075-.639.683.683,0,0,0-.113-.526c-.15-.188-.15-.15-.188-.075a.845.845,0,0,0-.038.451c.075.263.113.488.15.638a.492.492,0,0,1-.263.451c-.038-.376-.075-.826-.113-1.239.225-.075.113-.338-.075-.639-.113-.225-.188-.15-.263,0a.28.28,0,0,0,.038.338c-.038.113.038.263.075.526a7.337,7.337,0,0,1,.075,1.089.4.4,0,0,1-.338-.263,1.056,1.056,0,0,1-.038-.751c.075-.188.113-.451,0-.526s-.225,0-.3.15a1.589,1.589,0,0,0-.3.864,1.157,1.157,0,0,1-.113.488c-.038.113-.15.113-.225-.038a2.736,2.736,0,0,1-.188-1.127c0-.15-.038-.188-.075,0a2.439,2.439,0,0,0,.15,1.465C47.2,152.467,47.391,152.392,47.5,152.166Z'
          transform='translate(-46.809 -149.771)'
          fill='#fff'
        />
        <path
          id='Path_142308'
          data-name='Path 142308'
          d='M92.822,150.868c.075-.075.075-.075.113,0a.106.106,0,0,0,.188,0,.231.231,0,0,0,.038-.225c-.038-.075-.038-.038-.075.038-.038.15-.188.113-.188-.075s-.038-.075-.113.038c-.075.188-.188.15-.188-.038,0-.113-.038-.225-.113-.075a.4.4,0,0,0,.038.3C92.634,150.943,92.747,150.943,92.822,150.868Z'
          transform='translate(-90.325 -150.425)'
          fill='#fff'
        />
        <path
          id='Path_142309'
          data-name='Path 142309'
          d='M51.657,213.563c.113-.038.113-.038.113-.188s-.075-.075-.225-.038a2.391,2.391,0,0,0-1.014.714c-.15.188-.038.113.075.038A3.935,3.935,0,0,1,51.657,213.563Z'
          transform='translate(-50.286 -210.304)'
          fill='#fff'
        />
        <path
          id='Path_142310'
          data-name='Path 142310'
          d='M52.254,247.8c-.113.188-.338-.075-.188-.563a.955.955,0,0,0,.075-.263v-.263c0-.075-.075.038-.113.188a2.364,2.364,0,0,0-.15,1.052c.038.413.526.413.563-.113C52.442,247.616,52.367,247.616,52.254,247.8Z'
          transform='translate(-51.635 -242.14)'
          fill='#fff'
        />
        <path
          id='Path_142311'
          data-name='Path 142311'
          d='M69.149,157.287c0-.188-.038-.376-.038-.563.038,0,.038-.038.075-.038.113.338.6.263.751.038.075-.113.075-.113.15,0,.113.15.488.225.563,0a1.13,1.13,0,0,0,.038-.6c-.038-.188-.075-.15-.188-.075s-.038.113,0,.225c.038.451-.338.413-.376-.038-.038-.3-.075-.188-.113-.038-.188.563-.676.676-.6,0,0-.188-.113-.113-.188-.038l-.15.15c-.038-.526-.075-1.127-.15-1.878.15.188.3-.038.188-.225-.075-.15-.188-.526-.263-.714s-.113-.15-.263.038c-.15.15-.113.188-.075.376a3.859,3.859,0,0,0,.075.826c.038.3.113.939.188,1.728-.075.038-.188.113-.263.15-.038-.15-.15-.225-.3-.376-.225-.225-.376-.225-.563.038a.656.656,0,0,0-.15.563c0,.225-.038.3.15.376a.43.43,0,0,0,.376,0,1.746,1.746,0,0,1,.338-.113.36.36,0,0,1-.075.338c-.451.526-1.127.939-1.427.563a1.192,1.192,0,0,1-.188-.864c.038-.3-.038-.15-.075-.075a1.655,1.655,0,0,0,0,1.127c.188.376.526.526,1.052.263a1.444,1.444,0,0,0,1.014-1.2v-.263a.82.82,0,0,0,.188-.113v.639a.986.986,0,0,1-.451.977,2.328,2.328,0,0,1-1.089.451c-.826.113-1.014-.413-1.014-1.127,0-.113.038-.188.038-.338,0-.3-.038-.263-.15.038a2.457,2.457,0,0,0,.075,1.54c.3.563,1.277.413,2.028.038A1.634,1.634,0,0,0,69.149,157.287Zm-1.052-.563c-.188.075-.263-.038-.225-.15,0-.113.038-.188.188-.113a.638.638,0,0,1,.225.225A.5.5,0,0,1,68.1,156.724Z'
          transform='translate(-65.187 -153.201)'
          fill='#fff'
        />
        <path
          id='Path_142312'
          data-name='Path 142312'
          d='M144.831,235.711a.716.716,0,0,0,.075-.488c-.038-.113,0-.15-.113-.225-.113-.038-.15.038-.188.113-.038.113.038.263.188.188a.677.677,0,0,1-.038.376C144.718,235.824,144.756,235.861,144.831,235.711Z'
          transform='translate(-140.005 -230.986)'
          fill='#fff'
        />
        <path
          id='Path_142313'
          data-name='Path 142313'
          d='M152.651,183.159c-.15.188-.263.451-.038.6.113.075.338-.038.413-.188.075-.188.038-.225-.113-.038-.188.225-.413.038-.188-.3C152.876,183.009,152.763,182.971,152.651,183.159Z'
          transform='translate(-147.524 -181.477)'
          fill='#fff'
        />
        <path
          id='Path_142314'
          data-name='Path 142314'
          d='M138.283,153.061a1.982,1.982,0,0,0,.977-.789c.15.038.188-.075.113-.188a1.831,1.831,0,0,1,.113-.263c.113-.488-.376-.526-.488-.15a.39.39,0,0,0,.075.488,3.1,3.1,0,0,1-.9.789C138.02,153.1,138.057,153.173,138.283,153.061Zm.9-1.315c.038-.113.113-.038.113.038a.285.285,0,0,1-.075.15C139.147,151.9,139.147,151.821,139.184,151.746Z'
          transform='translate(-133.795 -151.341)'
          fill='#fff'
        />
      </g>
      <path
        id='Path_142315'
        data-name='Path 142315'
        d='M156.6,151.671a.39.39,0,0,0,.075.488c-.038.038-.075.113-.113.15l.188.15c.038-.075.113-.113.15-.188.15.038.188-.075.113-.188a1.831,1.831,0,0,1,.113-.263C157.2,151.333,156.749,151.3,156.6,151.671Zm.3.113a.285.285,0,0,1-.075.15c-.075-.038-.075-.113-.038-.188C156.824,151.633,156.9,151.709,156.9,151.784Z'
        transform='translate(-149.199 -147.953)'
        fill='#cfe7e8'
      />
      <path
        id='Path_142316'
        data-name='Path 142316'
        d='M124.906,203.592a1.4,1.4,0,0,0-.263.225c0-.15.038-.376.038-.451,0-.113-.038-.15-.113-.038a2.99,2.99,0,0,0-.263.751,10.768,10.768,0,0,1-1.5.864c-.188.075-.075.15.075.113a14.787,14.787,0,0,0,1.427-.526c.038.338.15.563.451.638.563.15,1.127-.338,1.765-1.2a1.74,1.74,0,0,0,1.5,1.127c.413.075.376.038.451-.188.038-.188.113-.225-.225-.3-.413-.15-.563-.451-.15-.639a6.894,6.894,0,0,1,1.352-.413c.263-.038.338-.038.413-.263.075-.263.113-.3-.15-.263a19.3,19.3,0,0,1-2.1.038c.3-.15.714-.3,1.014-.413.113.075.15.038.113-.038a1.334,1.334,0,0,0,.338-.15c.338-.188.488-.6.113-.864s-.676-.338-.864-.075c-.15.225-.3.451-.075.751-.338.113-.639.225-.939.338-.225.075-.338.113-.413.413s-.113.3.188.338a1.571,1.571,0,0,1,1.052.225c-.413.15-.714.451-.563.864a1,1,0,0,1-.526-.263c-.3-.3-.3-.826-.413-1.427a5.242,5.242,0,0,0-.488-1.352c-.075-.225-.113-.113-.188.038a.461.461,0,0,0-.075.451,15.647,15.647,0,0,1,.488,1.577,2.231,2.231,0,0,1-1.315,1.239c-.376.075-.488-.15-.451-.338a3.366,3.366,0,0,0,.714-.563,1.321,1.321,0,0,0,.225-1.653q.282.056-.113-.451c-.188-.225-.225-.188-.338.038-.075.188-.15.338.075.413a2.469,2.469,0,0,0,.225.451C125.62,203.1,125.244,203.291,124.906,203.592Zm3.568-1.653c0-.113.038-.113.225,0s.338.15.075.188c-.075.038-.188.038-.263.075A.557.557,0,0,0,128.474,201.939Z'
        transform='translate(-116.944 -195.479)'
        fill='#fff'
      />
      <g
        id='Group_26394'
        data-name='Group 26394'
        transform='translate(9.052 5.784)'
      >
        <path
          id='Path_142317'
          data-name='Path 142317'
          d='M239.177,238.15c-.188,0-1.014.038-1.577.038l.751.639a4.25,4.25,0,0,1,.563-.15c.263-.038.338-.038.413-.263C239.4,238.15,239.44,238.112,239.177,238.15Z'
          transform='translate(-235.497 -236.384)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142318'
          data-name='Path 142318'
          d='M192.8,200.8l.075.075C192.875,200.8,192.838,200.8,192.8,200.8Z'
          transform='translate(-192.8 -200.8)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142319'
          data-name='Path 142319'
          d='M225.213,203.669c.225-.113.488-.188.676-.263.113.075.15.038.113-.038a1.335,1.335,0,0,0,.338-.15c.338-.188.488-.6.113-.864s-.676-.338-.864-.075c-.15.225-.3.451-.075.751a4,4,0,0,0-.714.263Zm.826-.939c.188.113.338.15.075.188-.075.038-.188.038-.263.075a.661.661,0,0,1-.038-.225C225.777,202.617,225.852,202.617,226.039,202.73Z'
          transform='translate(-223.298 -202.054)'
          fill='#cfe7e8'
        />
      </g>
      <path
        id='Path_142320'
        data-name='Path 142320'
        d='M253.136,266.661a1.963,1.963,0,0,0,.451-.263c.15-.15.038-.113-.075-.075a3.9,3.9,0,0,1-.376.15c-.15.075-.188,0-.113-.263.075-.225,0-.225-.113-.188s-.15.113-.263.188c-.075.038-.075.113.113.038.15-.075.113,0,.075.188C252.76,266.623,252.873,266.736,253.136,266.661Z'
        transform='translate(-240.741 -257.158)'
        fill='#fff'
      />
      <g
        id='Group_26395'
        data-name='Group 26395'
        transform='translate(8.105 3.306)'
      >
        <path
          id='Path_142321'
          data-name='Path 142321'
          d='M173.424,150.126c.113-.263.188-.526.263-.714s.15-.15.113.038c-.038.338-.038.864.3,1.089.338.188.864-.15,1.052-.526.563.714,1.052.376,1.089-.3a2.7,2.7,0,0,0-.15-.826c-.075-.15,0-.15.075-.075s.225.15.15-.113a1.209,1.209,0,0,0-.3-.488c-.113-.113-.15-.038-.188.038a.5.5,0,0,1-.113.188c-.075.075-.038.113,0,.225.225.526.413,1.315.075,1.39-.3.075-.488-.6-.488-.9a3.062,3.062,0,0,0-.15-.939c-.075-.225-.113-.225-.188-.113-.038.075-.038.15-.113.225-.038.075,0,.113.038.263.188.864.15,1.239-.225,1.465s-.6,0-.6-.263a5.814,5.814,0,0,1,.038-.826c.038-.225.075-.3,0-.413s-.15-.075-.263.075a5.427,5.427,0,0,0-.563,1.164c-.075.263-.263.188-.376-.038a1.766,1.766,0,0,1-.075-.977c.038-.188.038-.263-.075-.075a1.384,1.384,0,0,0,.225,1.5C173.161,150.539,173.311,150.389,173.424,150.126Z'
          transform='translate(-172.627 -148.024)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142322'
          data-name='Path 142322'
          d='M207.807,153.69c0,.113,0,.263.075.038a1.506,1.506,0,0,0-.038-.714c-.075-.188-.113-.225-.188-.15s-.075.113,0,.188A1,1,0,0,1,207.807,153.69Z'
          transform='translate(-205.958 -152.602)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142323'
          data-name='Path 142323'
          d='M217.064,205.393c0,.3-.075.3-.188,0-.038-.113-.075-.113-.113.038,0,.263-.113.3-.188.038-.038-.113-.038-.225-.075-.038a.437.437,0,0,0,.075.376c.075.075.188.075.225-.038s.075-.113.15-.038.376,0,.225-.413C217.1,205.168,217.064,205.205,217.064,205.393Z'
          transform='translate(-214.427 -202.54)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142324'
          data-name='Path 142324'
          d='M292.156,151.433a.5.5,0,0,1,.188.488c-.038.188,0,.3.113.038a.861.861,0,0,0-.075-.751c-.113-.188-.15-.113-.225-.038C292.081,151.283,292.044,151.4,292.156,151.433Z'
          transform='translate(-286.477 -150.946)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142325'
          data-name='Path 142325'
          d='M251.275,150.181c0,.113.038.188.188.225.3.413.639.864.939,1.277.038.376.113.751.15,1.089a9.453,9.453,0,0,1,.113,2.216c-.075.338,0,.451.15-.038a3.574,3.574,0,0,0,.038-1.69c-.038-.3-.038-.638-.075-1.014a13.955,13.955,0,0,1,1.164,2.066c.113.3.225.338.113-.038a9.434,9.434,0,0,0-1.352-2.554,6.659,6.659,0,0,1-.038-.826c.113.113.188.038.15-.113a7.307,7.307,0,0,0-.3-.826c-.038-.15-.188-.15-.263.038-.075.225-.075.263,0,.413a5.787,5.787,0,0,0,.075.714,6.637,6.637,0,0,0-.451-.639c.188.075.338.038.15-.188-.188-.188-.451-.413-.6-.563s-.225-.038-.225.075C251.275,149.993,251.313,150.068,251.275,150.181Z'
          transform='translate(-247.511 -149.581)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142326'
          data-name='Path 142326'
          d='M293.825,178.916c-.038.188-.113.113-.15-.038-.038-.075-.075-.038-.075.113.038.15.075.263.188.15.075-.075.075-.075.188,0,.075.075.15,0,.188-.038a.7.7,0,0,0,0-.3c-.038-.188-.075-.075-.113.075s-.113.188-.15,0C293.9,178.728,293.825,178.765,293.825,178.916Z'
          transform='translate(-287.921 -177.264)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142327'
          data-name='Path 142327'
          d='M287.156,156.356c.038.676.113.977-.3,1.465a1.951,1.951,0,0,1-.939.676c-.263.075-.338.188.038.15.563-.038.638-.038,1.127-.6.263-.3.376-.676.376-1.577-.038-1.577-.225-2.254-.225-3.117.075.075.188.075.15-.075a3.513,3.513,0,0,1-.15-.6c-.075-.188-.113-.263-.188-.15s-.15.15-.188.225a.286.286,0,0,0,0,.3C287.081,154.892,287.118,155.68,287.156,156.356Z'
          transform='translate(-280.387 -152.264)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142328'
          data-name='Path 142328'
          d='M327.559,156.373a2.653,2.653,0,0,1,0,1.089c-.038.113.038.188.113,0a2.322,2.322,0,0,0,.075-1.577c-.038-.563-.263-2.028-.3-2.629l.038.038c.15.075.338.15.225-.075a4.478,4.478,0,0,1-.3-.751c-.075-.225-.113-.225-.225-.038-.225.3-.075.6,0,.977C327.334,154.683,327.522,156,327.559,156.373Z'
          transform='translate(-319.814 -152.093)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142329'
          data-name='Path 142329'
          d='M350.588,184.488a2.8,2.8,0,0,1,.263.451c.075.188.15.038.15-.075a2.826,2.826,0,0,1,0-.563c.075-.188.188.075.225-.038.038-.075-.038-.113-.075-.188-.038-.113-.075-.075-.15-.038a.343.343,0,0,0-.113.188v.413c0,.15-.038.15-.075.075a1.688,1.688,0,0,0-.225-.451.105.105,0,0,0-.188.075v.188C350.4,184.6,350.55,184.6,350.588,184.488Z'
          transform='translate(-342.054 -182.311)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142330'
          data-name='Path 142330'
          d='M352.8,208.881c0,.113.15-.038.263-.113s.451-.3.6-.376.113-.113.075-.263c0-.15-.113-.038-.225.075s-.451.338-.563.451S352.8,208.806,352.8,208.881Z'
          transform='translate(-344.341 -205.239)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142331'
          data-name='Path 142331'
          d='M328.4,155.284a1.832,1.832,0,0,1-.15-.864c0-.225-.038-.413-.113-.038-.225.9-.075,1.089.15,1.277.3.263.413.3.563.038.3-.451.413-.826.563-1.052.15-.188.15-.038.225.038a.827.827,0,0,0,.6.338c.263.038.714-.188.676-1.014-.038-.526-.075-1.2-.113-1.953.263.338.451.639.638.9a8.825,8.825,0,0,1,.113,1.277c-.038.413.075.413.15,0a3.754,3.754,0,0,0,0-.939,6.913,6.913,0,0,1,.639.939c.15.263.225.451.188-.038,0-.188-.075-.338-.3-.676-.225-.3-.413-.6-.6-.9-.075-.714-.15-1.5-.15-1.915.113.075.188,0,.113-.15a5.708,5.708,0,0,0-.225-.751c-.075-.15-.15-.15-.225.038-.075.15-.15.225-.113.338a15.311,15.311,0,0,0,.225,1.991c-.188-.263-.376-.526-.526-.751,0-.263-.038-.526-.038-.789.113.075.15.075.113-.113a5.019,5.019,0,0,0-.263-.676c-.075-.15-.113-.15-.188-.038a.454.454,0,0,0-.038.526c0,.188.038.338.038.526a3.935,3.935,0,0,0-.3-.413c.113.038.188-.038.075-.15s-.338-.3-.451-.413c-.113-.15-.188-.113-.188,0v.338a.587.587,0,0,0,.113.376c.263.338.563.676.789,1.014.113,1.2.15,1.991.15,2.479,0,.3-.113.451-.263.488s-.3-.038-.451-.263c-.263-.376-.376-.3-.563.038s-.338.6-.451.826C328.738,155.4,328.55,155.584,328.4,155.284Z'
          transform='translate(-320.73 -149.613)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142332'
          data-name='Path 142332'
          d='M408.687,160.645c0,.225.075.188.113,0a1.457,1.457,0,0,0-.075-.789c-.113-.263-.15-.15-.188-.075-.075.113-.075.15.038.263A2.136,2.136,0,0,1,408.687,160.645Z'
          transform='translate(-397.412 -159.144)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142333'
          data-name='Path 142333'
          d='M402.275,153.825c-.075-1.577-.263-2.254-.225-2.854q.225.169.113-.113c-.075-.188-.188-.413-.263-.639-.075-.188-.113-.15-.225,0a.317.317,0,0,0-.075.338c.225,1.915.338,2.93.376,3.531a1.844,1.844,0,0,1-.977,1.878c-.263.188-.563.376.075.225C401.674,156,402.35,155.59,402.275,153.825Z'
          transform='translate(-389.985 -149.995)'
          fill='#cfe7e8'
        />
        <path
          id='Path_142334'
          data-name='Path 142334'
          d='M389.034,262.975c-.113.15.15.225.188.113.075.113,0,.3-.113.413-.188.15-.075.188.038.075.113-.075.413-.413.225-.638A.238.238,0,0,0,389.034,262.975Z'
          transform='translate(-378.848 -257.492)'
          fill='#cfe7e8'
        />
      </g>
      <path
        id='Path_142335'
        data-name='Path 142335'
        d='M109.72,312.327c-.488,0-.939,0-1.427.038v-.15c.15-.188.113-.188,0-.376-.038-.075-.075-.15-.188,0s-.113.188.038.376v.15c-3.718.038-7.812,0-11.343-.038.376.638,1.653.6,2.817.6,1.052,0,4.207,0,8.488-.113V313c-.15.188-.113.188,0,.376.113.15.15.075.188,0a.116.116,0,0,0,.038-.075h.563c0,.075.113.113.15.113s.15-.038.15-.113h.639a.284.284,0,0,0,.263.15c.225,0,.338-.263.3-.6C110.359,312.365,110.171,312.29,109.72,312.327Zm-.526.751c0-.075-.113-.113-.15-.113s-.15.038-.15.113h-.563c0-.038-.038-.075-.113-.15v-.188c.488,0,1.014-.038,1.54-.038a1.04,1.04,0,0,0-.038.338Z'
        transform='translate(-92.255 -300.759)'
        fill='#fff'
      />
      <path
        id='Path_142336'
        data-name='Path 142336'
        d='M337.585,312.327c-.488,0-.939,0-1.427.038v-.15c.15-.188.113-.188,0-.376-.038-.075-.075-.15-.188,0s-.113.188.038.376v.15h-.038l.526.451c.376,0,.789-.038,1.164-.038a1.04,1.04,0,0,0-.038.338h-.563c0-.075-.113-.113-.15-.113s-.113.038-.113.075l.263.225v-.038h.638a.284.284,0,0,0,.263.15c.225,0,.338-.263.3-.6C338.223,312.365,338.035,312.29,337.585,312.327Z'
        transform='translate(-320.12 -300.759)'
        fill='#cfe7e8'
      />
    </svg>
  );
};
export default SaudiArabiaIcon;
