const BellIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16.312'
      height='18'
      viewBox='0 0 16.312 18'
    >
      <path
        id='bell_1_'
        data-name='bell (1)'
        d='M17.161,10.246,15.736,5.119a6.99,6.99,0,0,0-13.558.355l-1.1,4.961A3.75,3.75,0,0,0,4.735,15h.835a3.75,3.75,0,0,0,7.349,0h.628a3.75,3.75,0,0,0,3.613-4.753ZM9.245,16.5A2.25,2.25,0,0,1,7.134,15h4.223a2.25,2.25,0,0,1-2.112,1.5Zm6.094-3.888a2.232,2.232,0,0,1-1.792.889H4.735a2.25,2.25,0,0,1-2.2-2.738L3.642,5.8a5.49,5.49,0,0,1,10.649-.279l1.425,5.126a2.232,2.232,0,0,1-.376,1.963Z'
        transform='translate(-0.986 -0.002)'
        fill='#4c677b'
      />
    </svg>
  );
};
export default BellIcon;
