const QatarIcon = () => {
  return (
    <svg
      id='qatar'
      xmlns='http://www.w3.org/2000/svg'
      width='24.421'
      height='16'
      viewBox='0 0 24.421 16'
    >
      <path
        id='Path_142351'
        data-name='Path 142351'
        d='M11.368,104.276H.421A.421.421,0,0,1,0,103.855V88.7a.421.421,0,0,1,.421-.421H11.368Z'
        transform='translate(0 -88.276)'
        fill='#d8d8d8'
      />
      <path
        id='Path_142352'
        data-name='Path 142352'
        d='M183.389,88.277H167.372a.11.11,0,0,1,.033,0l2.546.778a.112.112,0,0,1,0,.215l-2.21.673a.112.112,0,0,0,0,.215l2.21.675a.112.112,0,0,1,0,.215l-2.21.673a.112.112,0,0,0,0,.215l2.21.675a.112.112,0,0,1,0,.215l-2.209.673a.112.112,0,0,0,0,.215l2.209.673a.112.112,0,0,1,0,.215l-2.21.675a.112.112,0,0,0,0,.215l2.21.673a.112.112,0,0,1,0,.215l-2.21.675a.112.112,0,0,0,0,.215l2.21.673a.112.112,0,0,1,0,.215l-2.21.675a.112.112,0,0,0,0,.215l2.21.673a.112.112,0,0,1,0,.215l-2.21.675a.112.112,0,0,0,0,.215l2.209.673a.112.112,0,0,1,0,.215l-2.209.673a.112.112,0,0,0,0,.215l2.21.675a.112.112,0,0,1,0,.215l-2.546.775a.112.112,0,0,1-.033,0h16.017a.421.421,0,0,0,.421-.421V88.7A.421.421,0,0,0,183.389,88.277Z'
        transform='translate(-159.389 -88.277)'
        fill='#873250'
      />
    </svg>
  );
};
export default QatarIcon;
